import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BadgeStyles, IDeal } from '../../../interfaces/deal';

@Component({
  selector: 'app-opened-deal',
  templateUrl: './opened-deal.component.html',
  styleUrls: ['./opened-deal.component.scss']
})
export class OpenedDealComponent implements OnInit {
  badgeViews: any;

  constructor(
    private dialogRef: MatDialogRef<OpenedDealComponent>,
    @Inject(MAT_DIALOG_DATA) public deal: IDeal
  ) {}

  ngOnInit() {
    this.badgeViews = BadgeStyles;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
