import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '../ui/ui.module';

import { PrivateLayoutComponent } from './private';

import {
  MatProgressBarModule,
  MatFormFieldModule,
  MatButtonModule,
  MatMenuModule,
  MatBadgeModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatDividerModule,
  MatCardModule,
  MatSelectModule,
  MatDialogModule,
  MatTabsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,

} from '@angular/material';

import { TopNavComponent } from './components/top-nav';
import { SearchComponent } from './components/search';
import { NotificationsComponent } from './components/notifications';
import { SideBarComponent } from './components/side-bar';

import { LogoComponent } from './components/logo';
import { MenuComponent } from './components/menu';
import { CreateTaskComponent } from './components/create-task';
import { FilterListComponent } from './components/filter-list';
import { AccountInfoComponent } from './components/account-info';
import { SideBarToggleComponent } from './components/side-bar-toggle';
import { SettingsComponent } from './components/settings/settings.component';
import { TaskListComponent, TaskListItemComponent } from './components/task-list';
import { EmailViewComponent } from './components/email-view';
import { RecipientsComponent } from './components/recipients';
import { RecipientsDialogComponent } from './components/recipients-dialog';
import { CreateEmailComponent } from './components/create-email';
import { EmailComponentsComponent } from './components/email-components';
import { ContactViewComponent } from './components/contact-view';
import { DeleteDialogComponent } from './components/delete-dialog';
import { DealComponent } from './components/deal';
import { CreateDealComponent } from './components/create-deal/create-deal.component';
import { AccountComponent } from './components/account';
import { IntegrationsComponent } from './components/integrations';
import { ColleaguesComponent } from './components/colleagues';
import { TagsComponent } from './components/tags';
import { NotificationsMenuComponent } from './components/notifications-menu';
import { ChatComponent, ChatListComponent, DialogComponent } from './components/chat';
import { EditDealComponent } from './components/edit-deal';
import { OpenedDealComponent } from './components/opened-deal';
import { OpenedTaskComponent } from './components/opened-task';
import { AllTaskComponent } from './components/task-list/all-task/all-task.component';

@NgModule({
  declarations: [
    PrivateLayoutComponent,
    NotificationsComponent,
    SearchComponent,
    TopNavComponent,
    SideBarComponent,
    LogoComponent,
    AccountInfoComponent,
    MenuComponent,
    FilterListComponent,
    SideBarToggleComponent,
    SettingsComponent,

    TaskListComponent,
    TaskListItemComponent,
    CreateTaskComponent,
    EmailViewComponent,
    RecipientsComponent,
    RecipientsDialogComponent,
    CreateEmailComponent,
    EmailComponentsComponent,
    ContactViewComponent,
    DeleteDialogComponent,
    DealComponent,
    CreateDealComponent,
    AccountComponent,
    IntegrationsComponent,
    ColleaguesComponent,
    TagsComponent,
    NotificationsMenuComponent,
    ChatComponent,
    DialogComponent,
    ChatListComponent,
    EditDealComponent,
    OpenedDealComponent,
    OpenedTaskComponent,
    AllTaskComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    MatMenuModule,
    RouterModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatProgressBarModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatCheckboxModule
  ],
  exports: [
    TaskListComponent,
    SearchComponent,
    EmailViewComponent,
    RecipientsComponent,
    CreateEmailComponent,
    ContactViewComponent,
    DealComponent,
    AccountComponent,
    NotificationsComponent,
    IntegrationsComponent,
    ColleaguesComponent,
    TagsComponent,
    ChatComponent
  ],
  providers: [MatDatepickerModule],
  entryComponents: [
    CreateTaskComponent,
    RecipientsDialogComponent,
    EmailComponentsComponent,
    DeleteDialogComponent,
    CreateDealComponent,
    EditDealComponent,
    OpenedDealComponent,
    OpenedTaskComponent
  ]
})
export class LayoutModule {}
