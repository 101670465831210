import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['../account/account.component.scss', './notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
