import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as layoutActions from '../../store/actions/layout.actions';

import { BadgeStyles, ITask, TaskStatuses, TaskTypes } from '../../interfaces/task';
import { IState } from '../../interfaces/state';

import $ from "jquery";
@Component({
  selector: 'app-all-task',
  templateUrl: './all-task.component.html',
  styleUrls: ['./all-task.component.scss']
})
export class AllTaskComponent implements OnInit {

  image:any;
  image2:any;

 constructor(
  private store: Store<IState>,
  ) { }

  ngOnInit() {
  this.store.dispatch(layoutActions.setPageTitle({ title: 'All Tasks' }));
    this.image = "../../../assets/content/images/taskicon.png"
    this.image2 = "../../../assets/content/images/images.png"
  }

}
