import { Component, HostBinding, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { IMenuItem } from '../../../interfaces/menu';
import { HttpService } from '../../../services/http.service';
import { IAccoutnInfo } from '../../../interfaces/account-info';

import { IState } from '../../../interfaces/state';
import { toggleSidebar } from '../../../store/actions/sidebar.actions';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @HostBinding('class.side-bar') true;
  @HostBinding('class.minimized') get minimizedState() {
    return this.minimized;
  }
  @HostBinding('class.mobile') get mobileState() {
    return this.mobile;
  }

  @Input() minimized: boolean;
  @Input() mobile: boolean;

  logo: string;
  minLogo: string;

  accountInfo: IAccoutnInfo;

  menuUrl: string;
  menu: Observable<IMenuItem[]>;

  constructor(private httpSv: HttpService, private store: Store<IState>) {}

  ngOnInit() {
    this.accountInfo = {
      name: 'Sierra Ferguson',
      avatar: '../../../../assets/content/images/avatar-3-46.jpg',
      minAvatar: '../../../../assets/content/images/avatar-3-46.jpg',
      email: 's.fergasuson.example@gmail.com'
    };

    this.menuUrl = '../../../../assets/data/menu.json';
    this.menu = this.getMenu(this.menuUrl);
    this.logo = '../../../../assets/svg/logo.svg';
    this.minLogo = '../../../../assets/svg/logo-min.svg';
  }

  getMenu(url: string): Observable<IMenuItem[]> {
    return this.httpSv.getData<IMenuItem[]>(url);
  }

  changeNavState(state: boolean): void {
    this.store.dispatch(toggleSidebar(state));
  }
}
