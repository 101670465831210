import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NgxEchartsModule } from 'ngx-echarts';

import { UiModule } from '../ui/ui.module';
import { LayoutModule } from '../layout/layout.module';

import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  MatFormFieldModule,
  MatSelectModule,
  MatCardModule,
  MatInputModule,
  MatTableModule,
  MatCheckboxModule,
  MatIconModule,
  MatDialogModule,
  MatDividerModule,
  MatTabsModule,
  MatButtonModule,
  MatGridListModule, MatChipsModule,MatListModule
} from '@angular/material';

import { PageDashboardComponent } from './dashboard';
import { PageTasksComponent } from './tasks';
import { PageContactsComponent } from './contacts';
import { PageEmailComponent } from './email';
import { RouterModule } from '@angular/router';
import { PageDealsComponent } from './deals';
import { PageProfileComponent } from './profile';
import { PageSettingsComponent } from './page-settings';
import { PageChatComponent } from './page-chat';
import { PageTypographyComponent } from './ui/typography';
import { PageGridComponent } from './ui/grid';
import { PageIconsComponent } from './ui/icons';
import { PageButtonsComponent } from './ui/buttons';
import { PageBadgesComponent } from './ui/badges';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { AllTaskComponent } from './all-task/all-task.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';

@NgModule({
  declarations: [
    PageDashboardComponent,
    PageTasksComponent,
    PageContactsComponent,
    PageEmailComponent,
    PageDealsComponent,
    PageProfileComponent,
    PageSettingsComponent,
    PageChatComponent,
    PageTypographyComponent,
    PageGridComponent,
    PageIconsComponent,
    PageButtonsComponent,
    PageBadgesComponent,
    TasksListComponent,
    AllTaskComponent,
    TaskDetailComponent
  ],
  imports: [
    CommonModule,
    LayoutModule,
    ReactiveFormsModule,
    UiModule,
    DragDropModule,
    MatFormFieldModule,
    MatCardModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatDialogModule,
    MatIconModule,
    NgxEchartsModule,
    MatDividerModule,
    MatTabsModule,
    MatGridListModule,
    MatChipsModule,
    RouterModule,
    MatButtonModule,
    MatListModule
  ],
  exports: [PageDashboardComponent]
})
export class PagesModule {}
