import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DealType, BadgeStyles, IDeal } from '../../../interfaces/deal';
import { fadeIn } from '../../../animations/form-error';
import { IUser } from '../../../interfaces/user';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

@Component({
  selector: 'app-edit-deal',
  templateUrl: './edit-deal.component.html',
  styleUrls: ['../create-deal/create-deal.component.scss', './edit-deal.component.scss'],
  animations: [fadeIn]
})
export class EditDealComponent implements OnInit {
  statuses: string[];
  badgeViews: any;
  types: string[];
  dealForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<EditDealComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { users: Observable<IUser[]>; deal: IDeal }
  ) {}

  ngOnInit() {
    this.badgeViews = BadgeStyles;
    this.types = Object.values(DealType);
    this.initTaskForm();
  }

  initTaskForm(): void {
    this.dealForm = new FormGroup({
      id: new FormControl(this.data.deal.id),
      type: new FormControl(this.data.deal.type),
      company: new FormControl(this.data.deal.company, [Validators.required]),
      associatedWith: new FormControl(this.data.deal.associatedWith.id, [Validators.required]),
      closeDate: new FormControl(new Date(this.data.deal.closeDate), [Validators.required]),
      price: new FormControl(this.data.deal.price, Validators.required),
      status: new FormControl(this.data.deal.status)
    });
  }

  editDeal(): void {
    this.data.users
      .pipe(
        map(users => {
          return users.find(user => user.id === this.associatedWith.value);
        })
      )
      .subscribe(user => {
        this.dealForm.get('associatedWith').setValue({ ...user });
        this.dealForm
          .get('closeDate')
          .setValue(
            `${
              MONTHS[this.closeDate.value.getMonth()]
            } ${this.closeDate.value.getDate()}, ${this.closeDate.value.getFullYear()}`
          );
        this.dialogRef.close(this.dealForm.value);
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  get selectedType(): string {
    return this.dealForm.get('type').value;
  }

  set selectedType(type: string) {
    if (Object.values(DealType).includes(type)) {
      this.dealForm.get('type').setValue(type);
    }
  }

  get company() {
    return this.dealForm.get('company');
  }

  get associatedWith() {
    return this.dealForm.get('associatedWith');
  }

  get closeDate() {
    return this.dealForm.get('closeDate');
  }

  get price() {
    return this.dealForm.get('price');
  }
}
