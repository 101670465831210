import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IState } from '../../interfaces/state';

import { ISettingTabs } from '../../interfaces/setting-tabs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import * as layoutActions from '../../store/actions/layout.actions';

@Component({
  selector: 'app-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.scss']
})
export class PageSettingsComponent implements OnInit, OnDestroy {
  displayedSection: string;
  settingsTabs: ISettingTabs[];
  routerSubscription: Subscription;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private store: Store<IState>
  ) {}

  ngOnInit(): void {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Email' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Search for a mail' }));

    this.initTabs();
    this.routerSubscription = this.activeRoute.queryParams
      .pipe(
        map(p => p.section),
        filter(v => v),
        distinctUntilChanged(),
        startWith('account')
      )
      .subscribe(section => (this.displayedSection = section));
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  setSection(section: string): void {
    this.router.navigate([], { queryParams: { section: section } });
  }

  initTabs(): void {
    this.settingsTabs = [
      {
        icon: 'account_settings',
        title: 'Account',
        desc: 'Your account preferences',
        value: 'account'
      },
      {
        icon: 'notifications',
        title: 'Notifications',
        desc: 'Your notifications preferences',
        value: 'notifications'
      },
      {
        icon: 'integrations',
        title: 'Integrations',
        desc: 'Add or remove integrations',
        value: 'integrations'
      },
      {
        icon: 'colleagues',
        title: 'Colleagues',
        desc: 'Your colleagues preferences',
        value: 'colleagues'
      },
      {
        icon: 'tags',
        title: 'Tags',
        desc: 'Add or remove tags',
        value: 'tags'
      }
    ];
  }
}
