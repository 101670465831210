import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  HostBinding
} from '@angular/core';

import { trigger, state, style, animate, transition } from '@angular/animations';

import { FormControl } from '@angular/forms';

import { IChat, IMessage, MessageStatus } from '../../../../interfaces/chat';
import { IUser } from '../../../../interfaces/user';

export const apear = trigger('apear', [
  state(
    '*',
    style({
      transform: 'translateY(0) scale(1)',
      opacity: 1
    })
  ),
  transition(':enter', [
    style({ opacity: 0.6, transform: 'translateY(15px) scale(0.8)' }),
    animate('200ms ease-out')
  ]),
  transition(':leave', [animate('200ms ease-out', style({ opacity: 0, bottom: '-28px' }))])
]);

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [apear]
})
export class DialogComponent implements OnInit, OnChanges {
  @ViewChild('chatEl', { static: false }) chatEl: ElementRef;

  @HostBinding('class.chat-dialog') true;
  @HostBinding('class.dialog-selected') get selectedState() {
    return this.chat;
  }

  @Input() chat: IChat;
  @Input() user: IUser;

  @Output() sendMessage: EventEmitter<IMessage>;
  @Output() deselectChat: EventEmitter<void>;

  chatInput: FormControl;
  constructor() {
    this.sendMessage = new EventEmitter<IMessage>();
    this.deselectChat = new EventEmitter<void>();
  }

  ngOnInit() {
    this.chatInput = new FormControl('');
  }

  ngOnChanges(): void {
    this.scrollChatToBottom();
  }

  getUserAvatar(id: any): string {
    return this.chat.members.find(user => user.id === id).avatar;
  }

  getUserTitle(id: any): string {
    return this.chat.members.find(user => user.id === id).name;
  }

  getMessageTime(message: IMessage): string {
    const DATE = new Date(message.date);
    return `${DATE.getHours()}:${DATE.getMinutes()}`;
  }

  onSendMessage(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.chatInput.value) {
      const DATE = new Date();
      const NEW_DATE = `${
        MONTHS[DATE.getMonth()]
      } ${DATE.getDate()}, ${DATE.getFullYear()}, ${DATE.getHours()}:${DATE.getMinutes()} `;

      const MESSAGE: IMessage = {
        text: this.chatInput.value,
        sender: this.user.id,
        date: NEW_DATE,
        status: MessageStatus.delivered
      };

      this.scrollChatToBottom();
      this.chatInput.setValue('');
      this.sendMessage.emit(MESSAGE);
    }
  }

  scrollChatToBottom(): void {
    if (this.chat) {
      requestAnimationFrame(() => {
        this.chatEl.nativeElement.scrollTop = this.chatEl.nativeElement.scrollHeight;
      });
    }
  }

  onDeselectChat(): void {
    this.chatInput.setValue('');
    this.deselectChat.emit();
  }
}
