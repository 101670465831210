import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IContact } from '../interfaces/contact';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  private contactSource: BehaviorSubject<IContact[]>;
  private contactsUrl: string;

  contacts$: Observable<IContact[]>;

  constructor(private httpSv: HttpService) {
    this.contactsUrl = '../../assets/data/contacts.json';
    this.contactSource = new BehaviorSubject([]);
    this.contacts$ = this.contactSource.asObservable();

    this.httpSv.getData<IContact[]>(this.contactsUrl).subscribe(res => this.contactSource.next(res));
  }

  getContacts(): IContact[] {
    return this.contactSource.value;
  }

  deleteContact(id: string): void {
    const contacts = this.contactSource.value.filter(c => c.id !== id);
    this.contactSource.next(contacts);
  }
}
