import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ITagGroup } from '../interfaces/tag';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  private tagsSource: BehaviorSubject<ITagGroup[]>;
  private tagsUrl: string;
  tags$: Observable<ITagGroup[]>;

  constructor(private httpSv: HttpService) {
    this.tagsUrl = '../../../assets/data/tags.json';
    this.tagsSource = new BehaviorSubject([]);
    this.tags$ = this.tagsSource.asObservable();

    this.httpSv.getData<ITagGroup[]>(this.tagsUrl).subscribe(res => this.tagsSource.next(res));
  }
}
