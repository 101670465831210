import {
  ChangeDetectionStrategy,
  Component, HostBinding,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { INotification } from '../../../interfaces/notification';

@Component({
  selector: 'app-notifications-menu',
  templateUrl: './notifications-menu.component.html',
  styleUrls: ['./notifications-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NotificationsMenuComponent implements OnInit {
  @HostBinding('class.app-notifications') true;
  @Input() notifications: INotification[];

  constructor() {}

  ngOnInit() {
    this.notifications = [];
  }
}
