import { Component, OnInit, HostListener, HostBinding } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { IState } from '../../interfaces/state';

import {
  setSidebarMinimized,
  setSidebarFullSize,
  setSidebarMobile
} from '../../store/actions/sidebar.actions';

import {
  setMobile,
  setDesktop,
  setTablet
} from '../../store/actions/layout.actions';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateLayoutComponent implements OnInit {
  @HostBinding('class.private-layoutActions') true;

  @HostBinding('class.minimized') get minimizedState() {
    return this.minimized;
  }
  @HostBinding('class.mobile') get mobileState() {
    return this.mobile;
  }

  constructor(private store: Store<IState>) {}

  sidebarState: string;
  ngOnInit() {
    this.store.subscribe(state => {
      this.sidebarState = state.sidebarState.type;
    });

    this.onWindowResize();
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (window.innerWidth > 992) {
      this.store.dispatch(setSidebarFullSize());
      this.store.dispatch(setDesktop());
    } else if (window.innerWidth > 768) {
      this.store.dispatch(setSidebarMinimized());
      this.store.dispatch(setTablet());
    } else {
      this.store.dispatch(setSidebarMobile());
      this.store.dispatch(setMobile());
    }
  }

  get minimized(): boolean {
    return this.sidebarState === 'minimized';
  }

  get mobile(): boolean {
    return this.sidebarState === 'mobile';
  }
}
