import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { IState } from '../../interfaces/state';
import { DealStatus, DealType, IDeal } from '../../interfaces/deal';
import { DealsService } from '../../services/deals.service';
import { UsersService } from '../../services/users.service';
import { CreateDealComponent } from '../../layout/components/create-deal/create-deal.component';
import { LayoutStates } from '../../interfaces/layout-state';
import * as layoutActions from '../../store/actions/layout.actions';
import { EditDealComponent } from '../../layout/components/edit-deal';
import { OpenedDealComponent } from '../../layout/components/opened-deal';
import { DeleteDialogComponent } from '../../layout/components/delete-dialog';

@Component({
  selector: 'page-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class PageDealsComponent implements OnInit {
  dealsSubscription: Subscription;
  deals: IDeal[];

  newDeals: IDeal[];
  wonDeals: IDeal[];
  lostDeals: IDeal[];

  filter$: Observable<DealType>;

  dealStatuses: typeof DealStatus;
  dragging: boolean;

  layout: string;
  layoutStates: typeof LayoutStates;
  constructor(
    private activeRoute: ActivatedRoute,
    private userSv: UsersService,
    private dealsSv: DealsService,
    private store: Store<IState>,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Email' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Search for a mail' }));

    this.layoutStates = LayoutStates;
    this.dealStatuses = DealStatus;
    this.newDeals = [];
    this.wonDeals = [];
    this.lostDeals = [];

    this.filter$ = this.activeRoute.queryParams.pipe(
      tap(() => this.dealsSv.updateDeals([...this.newDeals, ...this.wonDeals, ...this.lostDeals])),
      map(p => p.filter),
      startWith(this.activeRoute.snapshot.queryParams.filter)
    );

    this.dealsSubscription = combineLatest(this.filter$, this.dealsSv.deals$)
      .pipe(
        map(([filter, deals]) => {
          if (!filter) {
            return deals;
          }

          return deals.filter(d => d.type === filter);
        })
      )
      .subscribe(deals => {
        this.newDeals = deals.filter(d => d.status === DealStatus.new);
        this.wonDeals = deals.filter(d => d.status === DealStatus.won);
        this.lostDeals = deals.filter(d => d.status === DealStatus.lost);
      });

    this.store.subscribe(state => (this.layout = state.layoutState.type));
  }

  drop(event: CdkDragDrop<IDeal[]>, status: DealStatus): void {
    if (event.container === event.previousContainer) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      event.item.data.status = status;
    }
  }

  getTotalPrice(arr: IDeal[]): number {
    const getPrice = (d: IDeal) => d.price;
    const getTotal = (acc, val) => acc + val;

    return arr.map(getPrice).reduce(getTotal, 0);
  }

  addDeal() {
    const dialogRef = this.dialog.open(CreateDealComponent, {
      width: '435px',
      maxWidth: '91.5%',
      data: this.userSv.users$
    });

    dialogRef.afterClosed().subscribe(deal => deal && this.dealsSv.addDeal(deal));
  }

  editDeal(deal: IDeal) {
    const dialogRef = this.dialog.open(EditDealComponent, {
      width: '435px',
      maxWidth: '91.5%',
      data: {
        users: this.userSv.users$,
        deal
      }
    });

    dialogRef
      .afterClosed()
      .pipe(filter(res => !!res))
      .subscribe(res => {
        this.dealsSv.editDeal(res);
      });
  }

  openDeal(deal: IDeal): void {
    this.dialog.open(OpenedDealComponent, {
      width: '750px',
      maxWidth: '91.5%',
      height: '91vh',
      data: deal,
      autoFocus: false
    });
  }

  deleteDeal(deal: IDeal): void {
    const MSG = 'Are you sure you want to delete this deal?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => res))
      .subscribe(() => this.dealsSv.deleteDeal(deal));
  }

  onDragging(): void {
    this.dragging = true;
  }

  onRelease(): void {
    this.dragging = false;
  }
}
