import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpService } from './http.service';
import { IUser } from '../interfaces/user';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private profileUrl: string;
  private profileSource: BehaviorSubject<IUser>;
  profile$: Observable<IUser>;

  constructor(private httpSv: HttpService) {
    this.profileUrl = '../../../assets/data/profile.json';
    this.profileSource = new BehaviorSubject({
      avatar: '',
      firstName: '',
      role: '',
      lastName: '',
      email: '',
      phone: '',
      city: '',
      state: '',
      country: '',
      company: ''
    });

    this.profile$ = this.profileSource.asObservable();
    this.httpSv.getData<IUser>(this.profileUrl).subscribe(res => this.profileSource.next(res));
  }

  getProfile(): Observable<IUser> {
    return this.profileSource.asObservable().pipe(distinctUntilChanged());
  }

  editProfile(profile: IUser): void {
    this.profileSource.next({ ...this.profileSource.value, ...profile });
  }
}
