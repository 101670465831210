import { createAction, props } from '@ngrx/store';

const SET_DESKTOP = '[Window] Set desktop';
const SET_TABLET = '[Window] Set tablet';
const SET_MOBILE = '[Toggle] Set mobile';
const SET_PAGE_TITLE = '[Page] Set page company';
const SET_PAGE_PLACEHOLDER = '[Page] Set page placeholder';
const RESET_PAGE_DATA = '[Page] Reset page data';

export const setDesktop = createAction(SET_DESKTOP);
export const setTablet = createAction(SET_TABLET);
export const setMobile = createAction(SET_MOBILE);
export const setPageTitle = createAction(SET_PAGE_TITLE, props<{ title: string }>());
export const setPagePlaceholder = createAction(
  SET_PAGE_PLACEHOLDER,
  props<{ placeholder: string }>()
);
export const resetPageData = createAction(RESET_PAGE_DATA);
