import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { select, Store } from '@ngrx/store';

import { HttpService } from '../../../services/http.service';
import { INotification } from '../../../interfaces/notification';
import { IAccoutnInfo } from '../../../interfaces/account-info';
import { IState } from '../../../interfaces/state';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent implements OnInit {
  @HostBinding('class.top-nav') true;
  @HostBinding('class.minimized') get getMinimizedState() {
    return this.minimized;
  }

  @HostBinding('class.mobile') get getMobileState() {
    return this.mobile;
  }

  @Input() minimized: boolean;
  @Input() mobile: boolean;

  notifications: Observable<INotification[]>;
  notificationsUrl: string;
  placeholder: string;
  pageTitle: string;
  accountInfo: IAccoutnInfo;
  TitleAllTask : any;

  constructor(
    private store: Store<IState>,
    private httpSv: HttpService,
    private router: Router
  ) {}

  ngOnInit() {
    // console.log(this.router.url)

    if(this.router.url==='/private/tasks/all-task'){

      this.TitleAllTask = 'All Tasks'
    }else if(this.router.url==='/private/task-detail'){

      this.TitleAllTask = 'Task Detail'
    }else{
       this.TitleAllTask = '';
    }

    this.notificationsUrl = 'assets/data/notifications.json';
    this.notifications = this.httpSv.getData<INotification[]>(this.notificationsUrl);
    this.placeholder = 'Global search';

    this.accountInfo = {
      name: 'Sierra Ferguson',
      avatar: '../../../../assets/content/images/avatar-3-46.jpg',
      minAvatar: '../../../../assets/content/images/avatar-3-24.jpg',
      email: 's.ferguson@gmail.com'
    };

    this.store.subscribe(state => {
      this.pageTitle = state.layoutState.pageTitle;
      this.placeholder = state.layoutState.pagePlaceholder;
    });
  }
}
