import { Component, HostBinding, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ChatService } from '../../../services/chat.service';
import { ProfileService } from '../../../services/profile.service';
import { IChat } from '../../../interfaces/chat';
import { IState } from '../../../interfaces/state';

import { ChatListComponent } from './chat-list/chat-list.component';
import { IUser } from '../../../interfaces/user';
import { DialogComponent } from './dialog/dialog.component';
import * as layoutActions from '../../../store/actions/layout.actions';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('list', { static: false }) chatList: ChatListComponent;
  @ViewChild('dialog', { static: false }) dialog: DialogComponent;

  @HostBinding('class.app-page-chat') true;
  @HostBinding('class.chat-selected') get chatSelected() {
    return this.isChatSelected;
  }

  chatList$: Observable<IChat[]>;
  selectedChat$: Observable<IChat>;
  profile$: Observable<IUser>;

  messageSubscription: Subscription;
  isSelectedSubscription: Subscription;

  userId: any;

  isChatSelected: boolean;
  constructor(
    private chatSv: ChatService,
    private store: Store<IState>,
    private cdr: ChangeDetectorRef,
    private profileSv: ProfileService
  ) {}

  ngOnInit() {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Email' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Search for a mail' }));
    this.isChatSelected = true;
    this.chatList$ = this.chatSv.chatList$;
    this.profile$ = this.profileSv.profile$;
    this.userId = 0;
  }

  ngAfterViewInit(): void {
    this.selectedChat$ = combineLatest(this.chatList$, this.chatSv.selectedChat$).pipe(
      filter(([chat, selected]) =>  !!chat && !!selected),
      map(([chatList, selected]) => chatList.find(chat => chat.id === selected.id)),
      map(chat =>
        chat ? { ...chat, title: this.getChatTitle(chat), avatar: this.getChatAvatar(chat) } : null
      )
    );

    this.messageSubscription = combineLatest(
      this.dialog.sendMessage.asObservable(),
      this.chatSv.selectedChat$
    ).subscribe(([message, chat]) => this.chatSv.sendMessage(message, chat.id));

    this.isSelectedSubscription = this.chatSv.isChatSelected$.subscribe(
      isSelected => (this.isChatSelected = isSelected)
    );
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
    this.isSelectedSubscription.unsubscribe();
  }

  deselectChat(): void {
    this.chatList.deselectChat();
  }

  getChatTitle(chat: IChat): string {
    let title = chat.title;

    if (!title) {
      title = this.getMembersName(chat.members);
    }

    return title;
  }

  getMembersName(members: IUser[]): string {
    const notUser = (member: IUser) => member.id !== this.userId;
    const getNames = (user: IUser) => user.name;
    const reduceNames = (chat, user) => `${chat}, ${user}`;

    return members
      .filter(notUser)
      .map(getNames)
      .reduce(reduceNames)
      .slice(0, -1);
  }

  getChatAvatar(chat: IChat): string {
    let avatar = chat.avatar;

    if (!avatar) {
      avatar = this.getMembersAvatar(chat);
    }
    return avatar;
  }

  getMembersAvatar(chat: IChat): string {
    const notUser = (member: IUser) => member.id !== this.userId;
    return chat.members.find(notUser).avatar;
  }
}
