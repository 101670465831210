import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material';

import { IState } from '../../interfaces/state';
import { LayoutStates } from '../../interfaces/layout-state';
import { IContact } from '../../interfaces/contact';

import { ContactsService } from '../../services/contacts.service';
import * as layoutActions from '../../store/actions/layout.actions';
import { DeleteDialogComponent } from '../../layout/components/delete-dialog';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class PageContactsComponent implements OnInit {
  columns: string[];
  layoutState: LayoutStates;
  layoutStates: typeof LayoutStates;

  selectedData: SelectionModel<IContact>;

  companyFilter$: Observable<string>;
  companies$: Observable<string[]>;
  companySelect: FormControl;

  contacts$: Observable<IContact[]>;

  selectedContact: IContact;
  creating: boolean;

  constructor(
    private store: Store<IState>,
    private contactSv: ContactsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.store.dispatch(layoutActions.setPageTitle({ title: 'Tasks' }));
    this.store.dispatch(layoutActions.setPagePlaceholder({ placeholder: 'Search for a task' }));

    this.layoutStates = LayoutStates;
    this.selectedData = new SelectionModel(true, []);
    this.companySelect = new FormControl('All');

    this.companies$ = this.contactSv.contacts$.pipe(
      map(contacts => contacts.map(c => c.companyName)),
      map(companies => [...new Set(companies)].sort())
    );

    this.companyFilter$ = this.companySelect.valueChanges.pipe(
      distinctUntilChanged(),
      startWith(this.companySelect.value)
    );

    this.contacts$ = combineLatest(this.contactSv.contacts$, this.companyFilter$).pipe(
      map(([contacts, filter]) => {
        if (!filter || filter === 'All') {
          return contacts;
        }

        return contacts.filter(c => c.companyName === filter);
      })
    );

    this.store.subscribe(state => {
      this.layoutState = state.layoutState.type;

      switch (this.layoutState) {
        case LayoutStates.desktop:
          this.columns = ['select', 'name', 'email', 'company', 'role', 'forecast', 'activitiest'];
          break;
        case LayoutStates.tablet:
          this.columns = ['select', 'name', 'company', 'activitiest'];
          break;
        default:
          this.columns = ['select', 'name', 'activitiest'];
      }
    });
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selectedData.clear()
      : this.contactSv.getContacts().forEach(c => this.selectedData.select(c));
  }

  isAllSelected(): boolean {
    const SELECTED = this.selectedData.selected.length;
    const ROWS = this.contactSv.getContacts().length;
    return SELECTED === ROWS;
  }

  onBack(): void {
    this.creating = false;
    this.selectedContact = null;
  }

  onDeleteContact(id: string): void {
    this.creating = false;
    this.selectedContact = null;
    this.contactSv.deleteContact(id);
  }

  deleteContacts(): void {
    const MSG =
      this.selectedData.selected.length > 1
        ? 'Are you sure you want to delete this contacts?'
        : 'Are you sure you want to delete this contact?';

    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: MSG
    });

    REF.afterClosed()
      .pipe(filter(res => res))
      .subscribe((res) => {
        this.selectedData.selected.forEach(c => {
          this.contactSv.deleteContact(c.id);
        });
        this.selectedData.clear();
      });
  }
}
