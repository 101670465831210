import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';

import { IActivity, IContact, IContactInfo } from '../../../interfaces/contact';
import { DeleteDialogComponent } from '../delete-dialog';
import { ContactsService } from '../../../services/contacts.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-contact-view',
  templateUrl: './contact-view.component.html',
  styleUrls: ['./contact-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactViewComponent implements OnInit {
  @Input() contact: IContact;
  @Input() creating: boolean;

  @Output() back: EventEmitter<void>;
  @Output() delete: EventEmitter<string>;

  contactInfo: IContactInfo;
  infoFields: string[];
  activities: IActivity[];
  editing: boolean;

  constructor(private dialog: MatDialog) {
    this.back = new EventEmitter<void>();
    this.delete = new EventEmitter<string>();

    this.contact = {
      id: '_',
      twitter: '',
      facebook: '',
      website: '',
      role: '',
      email: '',
      companyName: '',
      name: '',
      minImg: '',
      recentActivity: '',
      activity: null,
      forecast: '',
      img: ''
    };
  }

  ngOnInit() {
    [this.contactInfo, this.infoFields] = this.setInfo(this.contact);
    this.activities = [
      {
        date: 'December 2019',
        tasks: [
          {
            title: 'You made a call to Jane Doe',
            date: 'Monday 3 at 14:30 EST',
            icon: 'call'
          },
          {
            title: 'Completed task <span class="color-accent">Office  meet-up</span>',
            date: 'Saturday 1 at 9:40 EST',
            icon: 'check'
          }
        ]
      },
      {
        date: 'October 2019',
        tasks: [
          {
            title: 'You made a call to Jane Doe',
            date: 'Monday 3 at 14:30 EST',
            icon: 'call'
          },
          {
            title: 'Jane Doe opened email: <span class="color-accent">New rules</span>',
            date: 'Friday 30 at 9:40 EST',
            icon: 'email'
          },
          {
            title: 'Completed task <span class="color-accent">Office  meet-up</span> ',
            date: 'Saturday 1 at 9:40 EST',
            icon: 'check'
          }
        ]
      }
    ];
  }

  onDelete(): void {
    const REF = this.dialog.open(DeleteDialogComponent, {
      width: '91.5%',
      maxWidth: '435px',
      data: 'Are you sure you want to delete this contact?'
    });

    REF.afterClosed()
      .pipe(filter(res => res))
      .subscribe(() => this.delete.emit(this.contact.id));
  }

  setInfo(contact: IContact): any[] {
    const [FIRST_NAME, LAST_NAME] = contact.name !== '' ? contact.name.split(' ') : ['', ''];
    const INFO = {
      'First name': FIRST_NAME,
      'Last name': LAST_NAME,
      Company: contact.companyName,
      Email: contact.email,
      Role: contact.role,
      Website: contact.website,
      Facebook: contact.facebook,
      Twitter: contact.twitter
    };

    return [INFO, Object.keys(INFO)];
  }

  edit(): void {
    if (!this.creating) {
      this.editing = !this.editing;
    }
  }

  onBack(): void {
    this.back.emit();
  }
}
