import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as layoutActions from '../../store/actions/layout.actions';

import { BadgeStyles, ITask, TaskStatuses, TaskTypes } from '../../interfaces/task';
import { IState } from '../../interfaces/state';
@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {
	selected = 'option2';
  public anyt={
    value: 'any', viewValue: 'any'
}
  constructor(
  private store: Store<IState>,
  ) { }

  ngOnInit() {
  this.store.dispatch(layoutActions.setPageTitle({ title: 'Task Detail' }));
  }

}
