export interface ILayoutState {
  type: LayoutStates;
  pageTitle?: string;
  pagePlaceholder?: string;
}

export enum LayoutStates {
  mobile = 'Mobile',
  tablet = 'Tablet',
  desktop = 'Desktop'
}
