import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import {
  BadgeStyles,
  TaskStatuses,
  TaskTypes
} from '../../../interfaces/task';

import { IUser } from '../../../interfaces/user';

import { fadeIn } from '../../../animations/form-error';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss'],
  animations: [fadeIn]
})
export class CreateTaskComponent implements OnInit {
  statuses: string[];
  badgeViews: any;
  types: string[];
  taskForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<CreateTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public users: Observable<IUser[]>
  ) {}

  ngOnInit() {
    this.badgeViews = BadgeStyles;

    this.statuses = Object.values(TaskStatuses);
    this.types = Object.values(TaskTypes);
    this.initTaskForm();
  }

  initTaskForm(): void {
    this.taskForm = new FormGroup({
      status: new FormControl(TaskStatuses.active),
      title: new FormControl('', [Validators.required]),
      type: new FormControl('Reminder', [Validators.required]),
      user: new FormControl('', [Validators.required]),
      dueDate: new FormControl('', [Validators.required])
    });
  }

  submitTask(): void {
    if (this.taskForm.valid) {
      this.users
        .pipe(
          map(users => {
            return users.find(user => user.id === this.user.value);
          })
        )
        .subscribe(user => {
          this.taskForm.get('user').setValue({ ...user });
          this.dialogRef.close(this.taskForm.value);
        });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  get taskStatus(): string {
    return this.taskForm.get('status').value;
  }

  set taskStatus(status: string) {
    if (Object.values(TaskStatuses).includes(status)) {
      this.taskForm.get('status').setValue(status);
    }
  }

  get title() {
    return this.taskForm.get('title');
  }

  get type() {
    return this.taskForm.get('type');
  }

  get user() {
    return this.taskForm.get('user');
  }

  get dueDate() {
    return this.taskForm.get('dueDate');
  }
}
